import React from 'react'
import CardTeaser from '../components/CardTeaser/index'
import CTABlock from '../components/CTABlock/index'
import HeroSingle from '../components/HeroSingle/index'
import Layout from '../components/Layout/index'
import { graphql } from 'gatsby'
import MassiveTitle from '../components/MassiveTitle'
import SEO from '../components/SEO'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props

    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="bg-light pb-md-5">
          <section className="position-relative">
            <HeroSingle data={data.page} />
            <CTABlock compact />
          </section>
          <div className="u-pull-up">
            <MassiveTitle modifiers={['z0']}>Applications</MassiveTitle>
          </div>
          <div className="container">
            <div className="row">
              {data.applications.edges.map(application => (
                <div
                  className="col-sm-6 col-lg-4 mb-2 mb-sm-3"
                  key={application.node.id}
                >
                  <CardTeaser data={application.node} />
                </div>
              ))}
            </div>
              <div className="row">
                  <h1>Or learn more about our approach by viewing our <a href="/learn">case studies</a>.</h1>
              </div>
          </div>
        </div>
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const ApplicationsOverviewQuery = graphql`
  query ApplicationsOverviewQuery {
    page: markdownRemark(frontmatter: { id: { eq: "applications_overview" } }) {
      ...SEO
      ...ctaBanner
      frontmatter {
        title
        introTitle
      }
    }

    applications: allMdx(
      filter: {
        frontmatter: { type: { eq: "application" }, hideInMenu: { ne: true } }
      }
      sort: { order: ASC, fields: frontmatter___priority }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            priority
            intro_title
            cover_image {
              childImageSharp {
                fluid(maxWidth: 500, maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
